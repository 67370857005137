.main_container {
    background: #F5F6F7;
    min-height: calc(100vh - 64px);
    padding: 0px  0px 6px 0px;
}
.add_main_container {
    background: #F5F6F7;
    padding: 0px  0px 6px 0px;
}
.indent_header_box {
    background: #FFFFFF;
    padding: 20px 24px;
}
.header_box {
    background: #FFFFFF;
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
}
.header_text {
    font-size: 17px !important;
    font-family:Montserrat, Medium !important;
    font-weight: bold !important;
    line-height: 42px !important;
}
.header-buttons {
    padding: 0px 15px; 
    font-family:Montserrat, Medium !important;
    font-weight: bold !important;
}
.filter_box {
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    border: 2px solid #DBE0E7;
}
.filter_field{
    background: #FFFFFF;
}
.arrow {
    cursor: pointer;
    vertical-align: middle;
    size: 10px;
    margin: 0px 10px 4px 0px;
}
.form_container {
    background: #FFFFFF;
    margin: 12px;
    padding: 12px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.add_route_form_container{
    background: #FFFFFF;
    margin: 12px;
    padding: 12px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    height: calc(100vh - 178px);
}
.details_container {
    padding: 20px 5px;
}
.border_bottom {
    border-bottom: 1px solid #e0e0e0;
}
.details_container_heading {
    color: #A7B1BF;
    font-size: 17px !important;
    font-family:Montserrat, Bold !important;
    font-weight: bold !important;
}
.details_container_content {
    padding: 12px 0px;
}
.cancel_button, .cancel_button:hover {
    background: #9D9D9D !important;
    color: #FFFFFF !important;
    border: none !important;
    width: 100%;
    padding: 8px 15px !important;
    text-transform: none !important;
    font-family:Montserrat, Bold !important;
}
.save_button, .save_button:hover {
    background: #366E93 !important;
    color: #FFFFFF !important;
    border: none !important;
    width: 100%;
    padding: 8px 15px !important;
    text-transform: none !important;
    font-family:Montserrat, Bold !important;
}
.custom_stepper .MuiStepIcon-root.MuiStepIcon-completed {
    color: #4FBA20;
}
.custom_stepper .MuiStepConnector-lineHorizontal {
    border-top-style: dashed;
    border-top-width: 2px
}
.drawer_header {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid #dfdfdf;
}
.custom_drawer_table .MuiTableHead-root  .MuiTableCell-root {
    padding: 0px 3px;
}
.custom_drawer_table .MuiTableRow-root .MuiTableCell-root {
    padding: 5px 5px;
}
.date_picker_field .MuiInputBase-input{
    padding: 10px;
}
.date_picker_field .MuiButtonBase-root{
    margin-right: 0px;
}
.date_picker_field .MuiInput-underline:before {
    border-bottom: none;
}
.custom_radio .MuiRadio-colorSecondary.Mui-checked {
  color: #366E93 !important;
}
.site-map .leaflet-marker-icon {
    width: 30px !important;
    height:  30px !important;
}
.add_fleet_block .MuiButtonBase-root{
    width: 100% !important;
    padding: 0px 16px !important;
}
.approval_dialog .MuiAppBar-positionFixed {
    position: relative;
}

.ant-select-dropdown{
    z-index: 9999 !important;
}
.unit_select .ant-select-single{
    margin-top: -3px !important;

}
.button .ant-select-show-search{
    margin-top: 0px!important;
}
.MuiBadge-badge{
    background: #E37614 !important
}
.notification{
    background-color: #c84e1d0d;
    color: #D3710F;
}
.drawer .css-10hburv-MuiTypography-root{
    font-size: 25px;
    letter-spacing: 0px;
    color: rgb(78, 76, 76);
    opacity: 1;
    font-weight: bold;
}

.popover .leaflet-popup-content p {
    /* margin: 17px 0; */
    margin: 5.3px 0px;
}
.toggle .css-j204z7-MuiFormControlLabel-root {
    border: 0px solid #707070
  }

.popup .MuiDialog-paperScrollPaper{
    display: flex;
    max-height: calc(100% - 124px);
    flex-direction: column;
    width: 70%;
}  
.dialog-popup .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width:100%;
}

.customDatePicker #daterangepicker {
    position: absolute;
    border-radius: 4px;
    padding: 4px;
    margin-top: 1px;
    top: 100px;
    left: -442px !important;
    z-index: 3001;
    border: 1px solid rgba(0,0,0,0.15);
    background-clip: padding-box;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    display: flex !important;
    width: 697px;
}
.customDtateRange .rangecontainer{
    width: 160px;
    display: flex !important;
    flex-direction: column;
}
.voucher_table .MuiTableCell-root {
    padding: 0px !important;
}
.tabs .css-1q2h7u5 {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    border-radius: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    max-width: 360px;
    min-width: 90px;
    position: relative;
    min-height: 48px;
    flex-shrink: 0;
    padding: 12px 16px !important;
    overflow: hidden;
    white-space: normal;
    text-align: center;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.6);
}

.delete_button,delete_button:hover {
    background: #F15F4C !important;
    color: #FFFFFF !important;
    border: none !important;
    width: 100%;
    padding: 8px 15px !important;
    text-transform: none !important;
    font-family:Montserrat, Bold !important;
}
.custom-select .ant-select-selection-item {
    color: #F80705 !important;
  }